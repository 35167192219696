import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ProposalsView from "./components/ProposalsView";
// import Login from "./components/Login";
import Settings from "./components/Settings";
import UploadFiles from "./components/UploadFiles";
import StatsView from "./components/StatsView";
import NotFound from "./components/NotFound";
import EditLogin from "./components/EditLogin";
import EditCompany from "./components/EditCompany";
import EditFund from "./components/EditFund";
import AdminPanel from "./components/AdminPanel";
import { includesUserRoles } from "./utils/user";
import Navmenu from "./components/utils/Navmenu";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import SpinnerFull from "./components/utils/SpinnerFull";
import Disclaimer from "./components/Disclaimer";
import Insights from "./components/Insights";
import { Typography } from "@mui/material";
import LogUser from "./components/LogUser";

const Application = (props) => {
  const [user, loading] = useAuthState(auth);
  const loggedInUser = user ? true : false;
  const userRoles = loggedInUser
    ? JSON.parse(user?.reloadUserInfo?.customAttributes)?.role
    : [];
  const [proposalLogs, setProposalLogs] = useState(false);

  useEffect(() => {
    if (user) {
      if ((Date.now() - user?.metadata.lastLoginAt) / 1000 / 60 > 720) {
        // 12 hours
        auth
          .signOut()
          .then(function () {
            alert("Your session has expired.");
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }, [loading, user]);

  return (
    <>
      {!loading ? (
        <BrowserRouter>
          <div className="App">
            <Navmenu setProposalLogs={setProposalLogs} />
            <Switch>
              <Route path="/" exact component={Insights} />
              {/* <Route path="/login">
                {loggedInUser ? <Redirect to="/" /> : <Login />}
              </Route> */}
              <Route path="/logUser">
                {loggedInUser ? <Redirect to="/" /> : <LogUser />}
              </Route>
              <Route exact path="/graphs" component={StatsView} />
              <Route
                exact
                path="/voting-records"
                render={(props) => (
                  <ProposalsView
                    {...props}
                    editorMode={false}
                    setProposalLogs={setProposalLogs}
                    logsMode={proposalLogs}
                  />
                )}
              />
              <Route path="/settings" component={Settings} />
              <Route
                path="/editFund"
                render={(props) =>
                  !loggedInUser ? (
                    <Redirect to="/denied" />
                  ) : loggedInUser &&
                    !includesUserRoles(userRoles, [
                      "EditInvestors",
                      "Admin",
                    ]) ? (
                    <Redirect to="/notfound" />
                  ) : (
                    <EditFund {...props} />
                  )
                }
              />
              <Route
                path="/edit"
                render={(props) =>
                  !loggedInUser ? (
                    <Redirect to="/denied" />
                  ) : loggedInUser &&
                    !includesUserRoles(userRoles, [
                      "EditProposals",
                      "Admin",
                    ]) ? (
                    <Redirect to="/notfound" />
                  ) : (
                    <ProposalsView
                      {...props}
                      editorMode
                      logsMode={proposalLogs}
                      setProposalLogs={setProposalLogs}
                    />
                  )
                }
              />
              <Route
                path="/uploads"
                render={(props) =>
                  !loggedInUser ? (
                    <Redirect to="/denied" />
                  ) : loggedInUser &&
                    !includesUserRoles(userRoles, ["Upload", "Admin"]) ? (
                    <Redirect to="/notfound" />
                  ) : (
                    <UploadFiles {...props} />
                  )
                }
              />
              <Route
                path="/editCompany"
                render={(props) =>
                  !loggedInUser ? (
                    <Redirect to="/denied" />
                  ) : loggedInUser &&
                    !includesUserRoles(userRoles, [
                      "EditCompanies",
                      "Admin",
                    ]) ? (
                    <Redirect to="/notfound" />
                  ) : (
                    <EditCompany {...props} />
                  )
                }
              />
              <Route
                path="/admin-panel"
                render={(props) =>
                  !loggedInUser ? (
                    <Redirect to="/denied" />
                  ) : loggedInUser &&
                    !includesUserRoles(userRoles, ["Admin"]) ? (
                    <Redirect to="/notfound" />
                  ) : (
                    <AdminPanel {...props} />
                  )
                }
              />
              <Route path="/upload-disclaimer" component={Disclaimer} />
              <Route path="/denied" component={EditLogin} />
              <Route component={NotFound} />
            </Switch>
            <footer id="footer">
              <Typography component="p" variant="body1" color="white.main" fontSize="0.8rem">
                Copyright © 2025 OxProx | Powered by X eleva Group
              </Typography>
            </footer>
          </div>
        </BrowserRouter>
      ) : (
        <SpinnerFull />
      )}
    </>
  );
};

export default Application;
